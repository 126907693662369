import api from ".";
import router from "@/router";
import store from "@/store";
import { ElMessage } from 'element-plus'
export const login = async (data) => {
    try {
        const result = await api.post('/authentication', data);
        if (result.code === 200) {
            ElMessage.success('登陆成功');
            window.localStorage.setItem('token', result.data.token);
            window.localStorage.setItem('userId', result.data.userId);
            router.replace('/home');
        } else {
            ElMessage.error('登录失败');
        }
    } catch (err) {
        ElMessage.error('登录失败');
    }
}
export const getUserInfo = () => {
    const id = window.localStorage.getItem('userId');
    api.get('/users/' + id).then(user => {
        store.commit('changeUserInfo', {
            id: user.id,
            name: user.name,
            email: user.email
        })
    });
}
export const logout = () => {
    window.localStorage.removeItem("token");
    window.localStorage.removeItem("userName");
    window.localStorage.removeItem("userId");
    router.replace("/login");
};
export const putUserPasword = (data) => {
    const id = window.localStorage.getItem('userId');
    api.patch('/users/' + id, {
        password: data.password
    }).then(() => {
        ElMessage.success('密码修改成功');
        logout();
    }).catch(() => {
        ElMessage.error('密码修改失败')
    })
}
export default {};
