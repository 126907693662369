import axios from "axios";
import router from "@/router";

const api = axios.create({
    baseURL: process.env.NODE_ENV === 'production' ? '' : '/api',
})

api.interceptors.request.use((config) => {
    const token = window.localStorage.getItem('token');
    if (token) {
        config.headers.Authorization = token;
    }
    return config;
})

/**
 * { "code": 401, "msg": "Not authenticated" } token 验证失败   
 * { "code": 401, "msg": "Invalid login" } 登录失败
 * { "code": 401, "msg": "Invalid authentication information (no `strategy` set)" } 登录接口参数缺少 'strategy'
 */
api.interceptors.response.use((response) => {
    const { code } = response.data;
    if (code === 401) {
        router.replace('/');
    }
    return response.data;
})

export default api;