<template>
  <div class="clients-list">
    <div class="header-filter">
      <el-input
        placeholder="请输入"
        style="width: 200px"
      ></el-input>
    </div>
    <div class="center-main">
      <el-auto-resizer>
        <template #default="{ height }">
          <el-table
            :data="clientsData"
            style="width: 100%;"
            :height="height"
            header-row-class-name="clients-table-header"
          >
            <el-table-column
              prop="name"
              label="名字"
              show-overflow-tooltip
            />
            <el-table-column
              prop="email"
              label="邮箱"
              show-overflow-tooltip
            />
            <el-table-column
              prop="subject"
              label="主题"
              show-overflow-tooltip
            />
            <el-table-column
              prop="phone"
              label="号码"
              show-overflow-tooltip
            />
            <el-table-column
              prop="messages"
              label="消息"
              show-overflow-tooltip
            />
            <el-table-column
              prop="createdAt"
              label="提交日期"
              show-overflow-tooltip
            />
          </el-table>
        </template>
      </el-auto-resizer>
      <div class="footer-pager">
        <el-pagination
          v-model="currentPage"
          v-model:page-size="pageSize"
          :page-sizes="[10, 20, 30]"
          layout="sizes, prev, pager, next, jumper, ->, total"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import {
  ElTable,
  ElTableColumn,
  ElMessage,
  ElInput,
  ElPagination,
  ElAutoResizer
} from "element-plus";
import { getClientList } from "../../api/clients";

const currentPage = ref(1);
const pageSize = ref(10);
const total = ref(50);
const clientsData = ref([]);
const getClientsData = () => {
  getClientList({
    $limit: pageSize.value,
    $skip: (currentPage.value - 1) * pageSize.value,
  })
    .then((res) => {
      clientsData.value = res.data;
      total.value = res.total;
    })
    .catch((err) => {
      ElMessage.error("客户信息获取失败");
      console.log(err);
    });
};
const handleSizeChange = (value) => {
  pageSize.value = value;
  getClientsData();
};
const handleCurrentChange = (value) => {
  currentPage.value = value;
  getClientsData();
};
onMounted(() => {
  getClientsData();
  document.onresize = () => {
    console.log(this);
  };
});
</script>
<style scoped>
.clients-list {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.header-filter,
.footer-pager {
  height: 60px;
  display: flex;
  align-items: center;
}
.footer-pager {
  justify-content: center;
}
.center-main {
  flex: 1;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(222, 222, 222, 0.8);
  display: flex;
  flex-direction: column;
}
.el-table {
  flex: 1;
}
.el-pagination {
  height: 60px;
}
/* :deep(.el-popper) {
  max-width: 260px;
} */
:deep(.clients-table-header) {
  color: #000;
  font-weight: 600;
  border-bottom: 2px solid var(--theme-color);
}
</style>
