import { createStore } from 'vuex'

export default createStore({
  state: {
    userInfo: {
      id: '',
      name: '',
      email: ''
    }
  },
  getters: {
  },
  mutations: {
    changeUserInfo(state, ploay) {
      state.userInfo = ploay
    }
  },
  actions: {
  },
  modules: {
  }
})
